import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'
// 货主总计页面接口
// 货主获取发票-场景类型
export const getSceneType = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReport/InvoiceSceneType`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 货主司机数量报表
export const getDriverNum = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReport/DriverNum`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 货主车辆数量报表
export const getVehicleNum = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReport/VehicleNum`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 船东数量报表
export const getShipOwnerNum = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReport/ShipOwnerNum`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 船舶数量报表
export const getShipNum = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReport/ShipNum`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取货主折线图数据
export const getInvioceReport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/DataReport/InvoiceReport`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 工运宝货主
// 获取所有任务
export const getTaskList = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReportSite/TaskList`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取任务详情
export const getTaskDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReportSite/TaskInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取发牌统计数据
export const getCardStatistic = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReportSite/MonthStat?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 获取货主首页人力资源统计数据
export const getPeopleStatistic = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/DataReportSite/LeaderToDriver?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}