<template>
  <div>
    <div :id="id"></div>
  </div>
</template>

<script>
import * as G2 from "@antv/g2";
export default {
  
  props: {
    id: {
      //图表id
      type: String,
    },
    InvoiceData: Array,
  },
  name: "spectaculars",
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    loadData() {
      const data = this.InvoiceData;
      // Step 1: 创建 Chart 对象
      const chart = new G2.Chart({
        container: this.id, // 指定图表容器 ID
        autoFit: true,
        height: 240,
        // 指定图表高度
      });
      // Step 2: 载入数据源
      chart.data(data);
      chart.scale({
        Date: {
          alias: "日期",
          type: "time",
        },
        Val: {
          alias: "金额",
          nice: true,
        },
      });
      chart.area().position('Date*Val').color('l(270) 0:#0F5FFF  1: #ffffff').shape("smooth");
      chart.line().position("Date*Val").shape("smooth");
      chart.tooltip({
        showCrosshairs: true,
      });
      // Step 4: 渲染图表
      chart.render();
      this.chart = chart;
    },
  },
  watch: {
    InvoiceData(val) {
      //数据更改重新渲染图表
      this.chart.changeData(val);
      this.chart.render();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
};
</script>

<style scoped>
</style>